/* Holds the entire grid */
.pp-grid-container 
{
  display: grid;
  grid-template-columns: auto;
  /* background-color: #2196F3; */
  /* padding: 10px; */
  /* width: 100vw; */
  /* border: solid; */
}

.pp-grid-container-three-cols
{
  display: grid;
  /* grid-template-columns: auto auto auto; */
  grid-template-columns: 0.5fr 0.5fr 2fr;
  /* background-color: #2196F3; */
  padding: 20px;
  /* padding-left: 100px;
  padding-right: 100px; */
}

.pp-grid-container-two-rows
{
  display: grid;
  grid-template-rows: 1fr 1fr;
  /* background-color: #2196F3; */
  /* padding: 10px; */
  /* margin: 20px; */
  /* row-gap: 10px; */
  /* space-bet */
}

.pp-grid-container-three-cols-dev-imgs
{
    display: grid;
    grid-template-columns: auto;
    /* background-color: #2196F3; */
    padding: 10px;
    /* margin: 20px; */
}

.pp-grid-container-two-cols
{
    display: grid;
    /* grid-template-columns: 1fr 3fr; */
    grid-template-columns: auto auto;
    /* background-color: #2196F3; */
    padding: 10px;
    /* width: 100%; */
}

.pp-grid-container-two-cols
{
    display: grid;
    /* grid-template-columns: 1fr 3fr; */
    grid-template-columns: auto auto;
    /* background-color: #2196F3; */
    padding: 10px;
    /* width: 100%; */  
}

.pp-grid-container-trinket
{
    display: grid;
    grid-template-rows: auto;
    background-color: #F9F9F9;
    padding: 80px;

}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
.pp-grid-item-col 
{
  /* Makes this grid-item into a flex container */
  display: flex;
  /* Center items horizontally */
  justify-content: center;
  /* Center items vertically */
  align-items: center;
  /* You can set direction as column or row depending on what you're putting into the flex container */
  flex-direction: column;

  /* Styling */
  /* background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 20px;
  font-size: 30px;
  text-align: center;
  /* margin-bottom: 0px; */
  /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
}

.pp-grid-item-col-subtitle
{
  /* Makes this grid-item into a flex container */
  display: flex;
  /* Center items horizontally */
  justify-content: flex-end;
  /* Center items vertically */
  align-items: flex-start;
  /* You can set direction as column or row depending on what you're putting into the flex container */
  flex-direction: column;

  /* Styling */
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  /* padding: 20px; */
  font-size: 30px;
  text-align: center;
  /* word-spacing: 0px; */
  /* height: 80px; */
}
.pp-grid-item-col-subtitle-details
{
  /* Makes this grid-item into a flex container */
  display: flex;
  /* Center items horizontally */
  justify-content: flex-start;
  /* Center items vertically */
  align-items: flex-start;
  /* You can set direction as column or row depending on what you're putting into the flex container */
  flex-direction: column;

  /* Styling */
  /* background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8); */
  /* padding: 20px; */
  font-size: 30px;
  text-align: center;
  /* word-spacing: 0px; */
  /* height: 80px; */
}


/* A cell in the grid that is also a flex-container, which holds flex items */
  /* This one specifically is meant to arrange items in rows */
.pp-grid-item-row 
{
  /* Makes this grid-item into a flex container */
  display: flex;
  /* Center items horizontally */
  justify-content: center;
  /* Center items vertically */
  align-items: center;
  /* You can set direction as column or row depending on what you're putting into the flex container */
  flex-direction: row;

  /* Styling */
  /* background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.pp-flexbox-item
{
  /* Fits and resizes everything into its respective box */
  min-width: min-content;

  /* Styling */
  /* padding: 0px; */
  margin: 10px; 
  /* border: 3px solid #333;
  background-color: #dfdfdf; */
}

.pp-flexbox-item-title
{
  /* Fits and resizes everything into its respective box */
  min-width: min-content;

  /* Styling */
  /* margin: 10px; */
  /* border: 3px solid #333;
  background-color: #dfdfdf; */
  margin-bottom: 10%; 
  margin-top: 10%;
}



/* Specific Styling */
.pp-title
{
  font-weight: 800;
  font-size: 96px;
  /* line-height: 131px; */
  /* margin-bottom: 80%;  */
  /* justify-content: center; */
}


.pp-title-line
{
  border: 1.5px solid #000000;
  display: flex;

}
.pp-project-logo
{
  position: relative;
  /* position: absolute; */
  /* left: 0%;
  right: 83.06%;
  top: 14.67%; */
  /* bottom: 83%; */
  width: 200px;
  height:100px;
  bottom: 120px;
  left: 600px;
  /* height: 10px; */

  /* box-shadow: 0px 4px 52px 11px rgba(255, 255, 255, 0.75); */
  /* align-self: start; */
  /* justify-content: flex-start; */
  /* margin-right:100px; */
  /* left: 10px; */
  /* padding: 0px; */
  /* margin-bottom: 0px; */
/* padding-bottom: 0px; */
/* margin-top: 0px;
padding-top: 0px; */
object-fit: cover;
/* border:solid black; */
}

.pp-project-img
{
  /* position: relative; */
  width: 100%;
  height: 570px;
  /* align-self: top; */
  /* object-fit: cover;
  background-size: cover; */
  /* object-fit:fill; */
/* position: absolute;
left:0;
right:0; */
object-fit: cover;
/* margin-bottom: 0px; */
/* padding-bottom: 0px; */
}
.pp-flexbox-item-goals-img
{
  /* Fits and resizes everything into its respective box */
  min-width: min-content;

  /* Styling */
  /* margin: 10px; */
  margin: 0px;
  padding:0px;
  /* border: 3px solid #333;
  background-color: #dfdfdf; */
  width: 100%;
  /* align-self: top; */
  /* object-fit: cover;
  background-size: cover; */
}
.pp-subtitle
{
  font-weight: 800;
  font-size: 32px;
  /* line-height: 39px; */
  text-align: left;
  /* align-items: left; */
  z-index: 2;
  


}

.dev-subtitle-col
{
  margin-top: 80px;
}

.pp-subtitle-sm
{
  font-weight: 800;
  font-size: 24px;
  /* line-height: 33px; */
  /* text-align: left; */
    /* align-items: left; */
  /* align-content: left; */
  /* justify-content: center; */
  /* margin: 0; */
}
.pp-details
{
  /* font-family: 'Manrope'; */
  /* font-style: normal; */
  font-weight: 200;
  font-size: 16px;
  line-height: 20px;
  color: #5C5C5C;
  text-align: left;
  list-style: none;
  /* color: red; */
  /* width: 100%; */
}
.pp-project-goals-img
{
  width: 100%;
  height: 570px;
}

/* .pp-project-development-overview-img
{
  width: 500px;
  height: 601px;
} */

.pp-development-img
{
  width: 681px;
  height: 400px;
  object-fit:cover; 
  background-color: white;
}
.back
{
  font-weight: 800;
  font-size: 30px;
  /* line-height: 41px; */
}

.pp-back
{
  text-decoration: none;
  color: black;
}

.pp-back-line
{
  width: 40px;
  /* height: px; */
  /* transform: rotate(90deg); */
  /* margin-bottom: 40px; */
  border: 1.5px solid #000000;
  display: flex;
  margin-left: 12px;
}
.pp-tag
{
    /* font-family: 'Manrope'; */
  /* font-style: normal; */
  /* font-weight: 200;
  font-size: 12px;
  line-height: 20px; */
  /* color: #A6A6A6; */
  text-align: center;
  /* align-items: center ; */
  /* justify-content: center; */
  /* align-content: center; */
  /* align-self: center; */

  max-width: 75px;
  /* width: 75px; */
  height: 25px;

  /* border: 0.2px solid #000000;
  border-radius: 2px; */
  /* width: fit-content; */
  /* text-size-adjust: auto; */
  
}
.tag
{
  border: 0.2px solid #A6A6A6;
  border-radius: 2px;
  background-color: none;
  color: #A6A6A6;
  font-weight: 200;
  font-size: 12px;
  /* line-height: 20px; */
  /* padding:0; */
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  /* text-justify: center; */
  /* align-items: left; */
  /* width: fit-content; */
  /* text-size-adjust: auto; */
  /* text-align: center; */
  
}
.flexbox-full-width
{
  width: 100%;
  /* height: 100%; */
  /* padding: 0px; */
  /* margin:0px; */
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;

  /* padding-bottom: 0px; */
}
.tag-padding
{
  padding: 0;
  flex-wrap: wrap;
  /* align-content: left; */
  /* align-self: left; */
  /* align-items: flex-start; */
  /* align-items: center; */

}

.col-full-width
{
  padding: 0px;
  /* margin: 0px;  */
  /* Moves tags to left if named this way */
  /* align-items: flex-start; */
  /* align-items: center; */
}

.logo
{
  position:relative;
  bottom: 200px;
  left: 600px; 
  /* margin: 0px; */
  /* padding: 0; */
  /* margin-bottom: 0px;
  padding: 0px; */
}
.pp-card
{
  position: relative;
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 4px 8px 0 rgba(54, 54, 54, 0.2);
  transition: 0.3s;
  background-color: white;
  border-radius: 5px; /* 5px rounded corners */
  padding: 50px;
  max-width: 400px;
  /* max-height: 30px; */
  height: 300px;
  /* margin: 50px; */  
}

/* Add some padding inside the card container */
.pp-card-text 
{
  padding: 20px;
  /* margin-bottom: 10px; */
  text-align: left;
  font-size: 22px;
}

.pp-colorblock
{
  position: absolute;
  /* background: #F2F4F5; */
  /* background: green; */
  /* width: 1500px; */
  width: 100vw;
  /* padding: 20px; */
  height: 400px;
  /* background-color: blue ; */
  /* display: inline-block; */
  /* margin-bottom: 190px; */
  /* padding-bottom: 400px; */
  /* object-fit: cover; */
  /* z-index: 1; */
}

.card-flexbox
{
  /* padding-top: 10px; */
  position: relative;
  top:80px;
  height: 400px;
  /* margin-bottom: 50px; */

}

.pp-grid-item-row-dev-imgs 
{
  /* Makes this grid-item into a flex container */
  display: flex;
  /* Center items horizontally */
  justify-content: center;
  /* Center items vertically */
  align-items: center;
  /* You can set direction as column or row depending on what you're putting into the flex container */
  flex-direction: row;

  /* Styling */
  /* background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 20px;
  font-size: 30px;
  text-align: center;
  grid-template-rows: 1fr 1fr;
  background-color: #F9F9F9;
}
.img-descriptions
{
  opacity: 100%;
  /* transition: 1s; */
  font-weight: 200;
  font-size: 16px;
  text-align: left;
  width: 654px;
  color: #5C5C5C;

}
.back
{
  margin-bottom: 300px;
  margin-top: 200px;
  text-decoration: none;

}

.col-bg
{
  /* color: #A6A6A6; */
  background-color: #F2F4F5;
}

/* .milestone
{
  width: 200px;
  opacity: 0%;
  transition: 1s;
} */

.pp-main-vid
{
  /* height: 800px; */
  width: 681px;
}

.pp-section-line
{
  border: 0.5px solid #000000;
  display: flex;
  /* height: 100%; */
  margin-top: 10px;
}
.pp-number
{
  font-weight: 200;
  font-size: 200px;
  line-height: 273px;

  color: rgba(231, 231, 231, 0.25);
  /* color:red; */
  position: relative;
  /* padding-bottom: 200px; */
  /* margin-left: 200px; */
  /* margin-top: -175px; */
  /* margin-bottom: 500px; */
}

.section
{
  margin-top: 100px;
  margin-bottom: 100px;
  z-index: 1;
}

.number
{
  position: absolute;
  /* z-index: 0; */
  margin-right: 100px;
  margin-bottom: 25px;

}
.tag-row
{
  align-items: left;
  align-self: left;
  /* background-color: blue; */
  /* Remove this to make tags centered instead */
  align-self: flex-start;
  /* margin-right: 270px; */
}
button:hover
{
  width: 100%;
  background-color: rgba(199, 216, 233, 1);
}

button
{
  transition: .8s all ease;
}

.button-flex
{
  width: 200px;
}

.multiple-buttons
{
  margin-left: -20px;
  margin-right: 30px;
}
.pp-back:hover
{
  color: gray;
}

.GIS-img
{
  object-fit:fill;
  /* object-fit: cover; */
  /* width: 100%; */
  /* height: 100%; */
}

.oceana-map
{
  object-fit:contain;
}

.oceana-userflow
{
  object-fit: contain;
}

.both-3dui
{
  /* object-fit: fill; */
  /* width: ; */
  object-position: 0px -250px; 
  /* height: 100%; */
  /* width: 100%; */
  /* height: 600px; */
  /* object-fit: fit; */
}

.vr-concept-img
{
  object-position: 0px -160px;
}

.vr-concept-img-ref
{
  position: relative;
  /* right: 0px; */
  /* bottom: -500px */
  text-align: right;
  font-size: 8px;
  padding: 0px;
  margin: 0px;
  bottom: 29px;
  text-decoration: none;
  color: white;
  bottom: 20px;
}
.vr-img-link
{
  text-decoration: none;
  color: white;
}

.oceanairl
{
  object-position: 0px -100px;
}


.oceana-manta
{
 object-position: 0px -750px;

}

.datch-meeting-img
{
 object-position: 0px -150px;

}

._3dui-dev-text
{
  align-items: flex-start;
}

.datch-overview-grid-container
{
  padding-top: 0px;
  /* margin-top: 0px; */
}

.ai-concept-img-ref
{
  position: relative;
  /* right: 0px; */
  /* bottom: -500px */
  text-align: right;
  font-size: 8px;
  padding: 0px;
  margin: 0px;
  bottom: 29px;
  text-decoration: none;
  color: white;
  bottom: 20px;
}

.disco-diffusion-tag
{
  min-width: 95px;
}

.esc-room-screenshot
{
  object-position: 0px -80px;
}

.disco-diffusion-clipart-ref
{
   position: relative;
  /* right: 0px; */
  /* bottom: -500px */
  text-align: right;
  font-size: 8px;
  padding: 0px;
  margin: 0px;
  bottom: 29px;
  text-decoration: none;
  color: white;
  bottom: 20px;
}

.disco-diffusion-clipart-link
{
  text-decoration: none;
  color: white;

}

.datch-proj-img
{
  object-position: 0px -100px;
}

.datch-cols
{
  margin-top: -200px;
}

.f-grid-container 
{
    background-color: white;
    /* background-color: ; */
}

@media screen and (min-width: 300px)
{
  /* .pp-card-text
  {
  font-size: 15px;
  } 

  .pp-card
  {
    max-width: 100px;
  } */

  .pp-site-buttons
  {
    flex-direction: column;
  }

  .card
  {
  flex-direction: column;
  }

  .pp-development-img
  {
    max-width: 340.5px;
    min-width: 100px;

    max-height: 200px;
    min-height: 50px;
  }

  .img-descriptions
  {
    max-width: 340.5px;
    min-width: 100px;
  }

  .oceana-map
  {
    max-width: 340.5px;
    min-width: 100px;
  }

  video
  {
    max-width: 340.5px;
    min-width: 100px;

    max-height: 200px;
    min-height: 50px;
  }

  .pp-card
  {
  
    /* border-radius: 5px; 5px rounded corners */
    /* padding: 50px; */
    max-width: 200px;
    /* max-height: 30px; */
    /* height: 50px; */
    /* margin: 50px; */  
  }
  .pp-card-text
  {
    font-size: 0.5em;
  }

  .pp-grid-container-two-cols
  {
    display: grid;
    grid-template-columns: auto;
  }

}

@media screen and (min-width: 768px)
{

  .pp-site-buttons
  {
    flex-direction: column;
  }

  .card
  {
  flex-direction: column;
  }

  .pp-development-img
  {
    max-width: 510.75px;
    min-width: 340.5px;

    max-height: 300px;
    min-height: 200px;
  }

  .oceana-map
  {
    max-width: 681px;
    height: 400px;
    max-height: 300px;
    min-height: 200px;
  }

  .img-descriptions
  {
    max-width: 510.75px;
    min-width: 340.5px;
  }

  video
  {
    max-width: 510.75px;
    min-width: 340.5px;

    max-height: 300px;
    min-height: 200px;
  }

  .pp-card
  {
  
    /* border-radius: 5px; 5px rounded corners */
    /* padding: 50px; */
    max-width: 300px;
    /* max-height: 30px; */
    /* height: 100px; */
    /* margin: 50px; */  
  }

  .pp-card-text
  {
    font-size: 0.6em;
  }

  .pp-grid-container-two-cols
  {
    display: grid;
    grid-template-columns: auto auto;
  }
  
}

@media screen and (min-width: 1200px)
{
  /* .pp-card-text
  {
  font-size: 25px;
  } 
 
  .pp-card
  {
    max-width: 400px;
  } */

  video
  {
    max-width: 681px;
    min-width: 400px;
    max-height: 681px;
    min-height: 200px;
  }

    .pp-development-img
  {
    max-width: 681px;
    min-width: 400px;
    max-height: 681px;
    min-height: 200px;
  }

  .pp-site-buttons
  {
    flex-direction: row;
  }

  .card
  {
  flex-direction: row;
  }

  .pp-development-img
  {
    width: 681px;
    height: 400px;
    max-height: 681px;
    min-height: 200px;
  }

  .oceana-map
  {
    width: 681px;
    height: 400px;
    max-height: 681px;
    min-height: 400px;
  }

  .img-descriptions
  {
    width: 681px;
    max-width: 681px;
    min-width: 200px;
  }

  .pp-card
  {
  
    /* border-radius: 5px; 5px rounded corners */
    /* padding: 50px; */
    max-width: 400px;
    /* max-height: 30px; */
    height: 300px;
    /* margin: 50px; */  
  }

  .pp-card-text
  {
    font-size: 0.65em;
  }

  .pp-grid-container-two-cols
  {
    display: grid;
    grid-template-columns: auto auto;
  }

}