/* Holds the entire grid */
.p-grid-container 
{
    display: grid;
    /* grid-template-columns: auto auto; */
    /* Shorthand method for evenly-spacing the 2 columns */
    grid-template-columns: repeat(2, minmax(0, 1fr));
    /* background-color: #2196F3; */
    padding: 10px;
    /* Makes each project segment the same size */
    width: 100%;
    height: 100%;
    /* border: solid; */
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
.p-grid-item-col 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
    /* width:100px; */

}

.p-grid-item-col-space 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;

    height: 100px;
}

  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
.p-grid-item-row 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
}

.p-grid-container-single-row
{
    display: grid;
    grid-template-rows: auto;
    /* background-color: #2196F3; */
    padding: 10px;
}


.p-grid-item-row-tags
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    /* padding: 20px; */
    font-size: 30px;
    text-align: center;    
}

.p-flexbox-item
{
     /* Fits and resizes everything into its respective box */
    min-width: min-content;

    /* Styling */
    margin: 10px;
    /* border: 3px solid #333;
    background-color: #dfdfdf; */
    
    
}

.p-flexbox-item-see-project
{
     /* Fits and resizes everything into its respective box */
    min-width: min-content;

    /* Styling */
    /* margin: 30px; */
    /* margin-right: 500px; */
    /* margin-left: -80px; */
    /* margin-top: 70px; */
    /* border: 3px solid #333;
    background-color: #dfdfdf; */
    /* align-items: left; */
    /* align-self: left; */
    /* align-content: left; */
    /* justify-content: left; */
    /* justify-self: left; */
    /* justify-items: left; */
}

.p-tags-container
{
    position: relative;
    bottom: 50px;
    left: 28px;
    /* background-color: rgba(0, 0, 0, 0); */
    border: none;
}

.p-grid-item-col-l-align
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    background-color: rgba(255, 255, 255, 0.8);
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
    /* margin-left: 10px; */
}

.p-grid-item-col-r-align
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-end;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
    margin-right: 10px;

}

.p-grid-item-col-center-align
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
}

.p-grid-container-single-row
{
    display: grid;
    grid-template-rows: auto;
    /* background-color: #2196F3;*/
    padding: 10px;
    /* width: 50px; */
}

/* Specific styling */
.p-projects
{
    font-weight: 800;
    font-size: 30px;
    color: #000000;
    text-align: left;
}
.p-pro-line
{
    border: 1.5px solid #000000;
    display: flex;
    height: 100%;
    max-width: 50px;
}

.p-personal-line
{
    position: relative;
    /* background-color: rgba(0, 0, 0, 0); */
    border: 1.5px solid #000000;
    vertical-align: text-top;
    height: 100%;
    /* height: 1px; */


}
.personal
{
    text-align: end;
    margin: 0px;
}

.p-thumbnail
{
    width: 233px;
    height: 233px;
    object-fit:cover;
    /* box-shadow: -2px 0px 5px 1px rgba(0, 0, 0, 0.25); */
    /* box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.25); */
    filter: grayscale(1);
    transition: 0.8s ease-in-out;

}
.p-card-grid-container:hover .p-thumbnail
{
    filter: none;
    /* transform:scale(1.2); */
    /* transition: 0.8s ease-in; */
    /* transform:scaleX(2.5); */
}

.p-title 
{
    font-weight: 800;
    font-size: 20px;
    line-height: 33px;
    color: #000000;
    text-align: left;
    /* width: 2px; */
    /* min-width: 300px; */
    /* width:150px; */
    /* white-space: nowrap; */
}
.p-descr
{
    font-weight: 200;
    font-size: 15px;
    line-height: 19px;
    color: #808080;
    text-align: left;
    /* width: 200px; */
    /* width: 300px; */
}
.p-see-proj
{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    text-align: none;
    text-decoration: none;
    
}

.p-see-proj:hover
{
    color: gray; 
    
}
.p-see-proj-line
{
    height: 1px;
    width: 23.3px;
    border: 1.5px solid #000000;
    display: flex;
}

.p-thumbnail-box
{
    margin: 0px;
}

/* Specific styling */
.p-thumbnail
{
    width: 233px;
    height: 233px;
    object-fit:cover;
    object-position: 30%;
    /* margin-top: 30px; */
    
}

._3dui-thumbnail-pos
{
    object-position: 70px -50px;
    
}

.vre-thumbnail-pos
{
    object-position: -20px 0px;
}

.giga-golf-thumbnail-pos
{
    object-position: -50px 0px;
}

.catbot-thumbnail-pos
{
    object-position: -50px 0px;
}

.candle-chaos-thumbnail-pos
{
    object-position: -30px 0px;
}

.datch-thumbnail-pos
{
    object-position: -100px 0px;
}

.tag
{
    background-color: white;
    /* background: #FFFFFF; */
    height: 15px;
    /* min-width: 14px; */
    padding-left: 10px;
    padding-right: 10px;
    font-size: 8px;
    font-weight: 800;
    /* border-radius: 10px; */
    border-radius: 15px;
    /* padding: 5%; */
    justify-content: center;
    align-items: center;
    text-align: center;
}

.project-info
{
    justify-content: space-between;
    margin-right: 30px;
    /* width: 310px; */
    /* box-shadow: 2px 0px 5px 1px rgba(0, 0, 0, 0.25); */
    /* box-shadow: 2px 0px 5px 10px rgba(0, 0, 0, 0.25); */
}

.descr
{
    position: relative;
    bottom: 30px;
}
.descr-1-line
{
    position: relative;  
    bottom: 50px;
}
.coming-soon
{
    text-align: center;
}
.coming-soon-line-container
{
    position: relative;
    bottom: 54px;
    align-items: center;
    background-color:rgba(0, 0, 0, 0);
    border: none;
}

.p-card-grid-container
{
    height: auto;
    justify-content: center;
    /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
    grid-template-columns: auto auto;
}

.p-project-card-col
{
    padding: 0px;
    margin: 0px;
    /* width: fit-content; */
    /* width: 567px; */
    justify-content: center;
    margin-left: 30px;
    overflow: hidden;
    /* box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.25); */


}

.projects-title-bg
{
    background-color: rgba(0, 0, 0, 0);
}

/* .ai-img-thumbnail
{
    height: 200px;
} */

/* #duotone {
  background: url('https://jmperezperez.com/static/images/posts/fecolormatrix-kanye-west.jpg') no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
}

#duotone::before {
  background-color: rgb(240, 14, 46);
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  mix-blend-mode: darken;
  position: absolute;
  top: 0;
  left: 0;
} */

/* #duotone::after {
  background-color: rgb(25, 37, 80);
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  mix-blend-mode: lighten;
  position: absolute;
  top: 0;
  left: 0;
} */

.p
{
    /* background-color: rgba(199, 216, 233, 1); */
  background-color: #FAFAFA;

}

@media screen and (min-width: 300px)
{

    .all-projects-grid
    {
        /* grid-template-columns: repeat(1, minmax(0, 1fr)); */
        grid-template-columns: 1fr;
        padding-bottom: 100px;
        
    }

    .p-card-grid-container
    {
        max-width: 300px;
        /* grid-template-columns: repeat(1, minmax(0, 1fr)); */
        grid-template-columns: 1fr;
        /* box-shadow: 2px 0px 5px 1px rgba(0, 0, 0, 0.25); */
        box-shadow: 0px 4px 8px 0 rgba(54, 54, 54, 0.2);
        padding: 0px;

       


    }

    .p-project-card-col
    {
      
        margin: 0;
    }

    
    .project-info
    {
        margin-right: 0;
        margin-left: 0px;
    }
    .p-thumbnail
    {
        width: 300px;
        
    }

    ._3dui-thumbnail-pos
    {
        object-position: 0px 0px;
    }
    
}

@media screen and (min-width: 768px)
{

    .all-projects-grid
    {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    
    .p-card-grid-container
    {
        max-width: 600px;
        grid-template-columns: 1fr 2fr;

    }
    .p-project-card-col
    {
        align-items: flex-end ;
        margin-right: 0px; 
    }
    
    ._3dui-thumbnail-pos
    {
        object-position: 70px -50px;
    }
  
}

@media screen and (min-width: 1200px)
{

    .all-projects-grid
    {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        
    }
    
    .p-card-grid-container
    {
        max-width: 700px;
        grid-template-columns: 1fr 2fr;

    }

    .p-project-card-col
    {
        align-items: flex-end ;
        margin-right: 0px; 
    }

    ._3dui-thumbnail-pos
    {
        object-position: 70px -50px;
    }
}