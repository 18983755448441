/* Holds the entire grid */
/* This one floats above everything else on the page */
.n-grid-container {
    display: grid;
    grid-template-columns: auto auto;
    /* background-color: hsl(207, 90%, 54%); */
    padding: 0px;
    /* margin: 0px; */
    /* Makes grid float on top and stay in place when scrolling */
    /* position: absolute; */
    position: fixed;
    /* Always halfway from the top of the screen */
    top: 30%;
    /* Width of grid is 100% of the viewport size -- when using vh*/
    width: 100%;
    /* Keeps one component on left side of screen and the other on right side */
    justify-content: space-between;
    /* Idk right now. Look up later  */
    align-items: flex-end;
    z-index: 1;
    pointer-events:none;
  }

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
  .n-grid-item-col {
    /* Makes this grid-item into a flex container */
    display: flex;
    flex-direction: column;
    
    /* Styling */
    /* Navbar background color */
    /* background-color: rgba(255, 255, 255, 0.8); */
    background-color: rgba(249, 249, 249, 0.5);
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    font-size: 30px;
    text-align: center;
    pointer-events:all;
  }


  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
  .n-grid-item-row {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
    
  }

  .n-flexbox-item
{
     /* Fits and resizes everything into its respective box */
    min-width: min-content;
    max-width: fit-content;
    /* Styling */
    /* margin: 20px;
    margin: 20px; */
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    /* border: 3px solid #333;
    background-color: #dfdfdf; */
    /* Writes text so that bottm of the text is facing left side of screen */
    writing-mode: vertical-lr;
    /* Rotate so that bottom faces right side of screen */
    transform: rotate(180deg);
    
}

 .n-flexbox-item-dark-mode
{
     /* Fits and resizes everything into its respective box */
    min-width: min-content;
    max-width: fit-content;
    /* Styling */
    margin-right: 30px;
    margin-top: 80%;
    /* border: 3px solid #333;
    background-color: #dfdfdf; */
    /* Writes text so that bottm of the text is facing left side of screen */
    writing-mode: vertical-lr;
    /* Rotate so that bottom faces right side of screen */
    transform: rotate(180deg);
  text-decoration: none;
    
}


/* This used to be the home icon */
.n-logo
{
    width: 51px;
    height: 86px;
}

.work-link
{
  /* padding-left: 10px; */
  padding-right: 21px;
  display: inline;
  color: #181717;
  font-weight: 300;
  font-size: 14px;
  text-decoration: none;
  color: #000000;


}

.about-link
{
  padding-left: 10px;
  padding-right: 21px;
  display: inline;
  font-weight: 300;
  font-size: 14px;
  text-decoration: none;
  color: #000000;


}

.contact-link
{
  padding-left: 10px;
  padding-right: 21px;
  display: inline;
  font-weight: 300;
  font-size: 14px;
  text-decoration: none;
  color: #000000;


}

.mode-switch
{
  /* Font formatting */
  font-weight: 300;
  font-size: 21px;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;

  /* ISSUE: THIS STYLING IS NOT WORKING! */
  text-decoration: none;
  color: #000000;

}

/* Prevents horizontal scrolling */
html, body {
    max-width: 100%;
    overflow-x: hidden;
}

.initials
{
  transform: rotate(270deg);
  position: absolute;
  /* margin-bottom: 500px; */
  /* bottom:500px; */
  top: -150px;
  left: 25px;
}

.i-initials:hover
{

  color:gray;
}


/* Setup for elements inside grid */
.i-initials
{
    font-weight: 800;
    font-size: 36px;
    align-items: flex-end;

    /* transform: rotate(1000deg); */

    color: #000000;
    /* color: purple;  */
    position: relative;
    text-decoration: none;

}

.home
{
  /* Prevents the home button (initials) from scrolling with the page */
  position: absolute;
}

/* Nav links Underline on hover effect */
.nav-link::after {


  content: '';
  position: absolute;
  height: 100%;
  width: 2px;
  bottom: 0;
  left: 0;
  /* background-color: rgba(199, 216, 233, 1); */
  background-color: #0077b6;
  /* From center */
  /* transform-origin: center; */
  /* From left */
  transform-origin: top left;

  transition: transform 0.25s ease-out;
  transform: scaleY(0);

}

.nav-link:hover::after {
  transform: scaleY(1);
}
.nav-link:hover {
  color: gray;
  transition: transform 5s ease-in;
  transition: transform 5s ease-out;
}

/* @media screen and (max-width: 320px)
{
  .initials
  {
    top: -160px;
  }
  
} */

