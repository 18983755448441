.i-border-top
{
    position: absolute;
    height: 3.69px;
    min-width: 100%;
    max-width: 100%;
    top: 0px;
}

/* Holds the entire grid */
.i-grid-container 
{
    display: grid;
    /* Using fractionals to make columns even (middle takes up 2/4, others take up 1/4 each of screen) */
    grid-template-columns: 1fr 2fr 1fr;  
    /* background-color: #2196F3; */
    padding: 10px;
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
.i-grid-item-col 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
}

.i-grid-item-col-l-align 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
}

  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
.i-grid-item-row 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
}
  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
.i-grid-item-row-bottom
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: flex-end;
    /* Center items vertically */
    align-items: right;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    /* padding: 20px; */
    font-size: 30px;
    text-align: center;
    padding-bottom: 100%;
    /* padding-bottom: -100px; */
    /* transform:rotate(-90deg); */
}

.i-flexbox-item
{
     /* Fits and resizes everything into its respective box */
    min-width: min-content;

    /* Styling */
    margin: 10px;
    /* border: 3px solid #333;
    background-color: #dfdfdf; */
}

.i-welcome
{
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    color: #000000;
}

.i-line
{
    display: flex;
    width: 18px;
    height: 0px;
    border: 1.5px solid #000000;
}

.i-intro
{
    /* Keeps text in center of box. (Center aligned text)*/
    /* text-align: center; */
    font-style: normal;
    font-weight: 300;
    font-size: 120px;
    /* color: #5B6DCD; */
    font-family: JetBrains Mono;
    font-weight: 600;
    /* transform: rotate(75deg); */

}
.intro
{
    position: absolute;
    transform: rotate(-10deg);
    z-index: 0;

}

.i-name
{
    /* Center content on y axis -- vertical position */
    align-items: center;
    /* Center content on x axis horizontal position */
    justify-content: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    text-align: center;

    /* color: white; */
    position: relative;
    /* z-index: 1; */
}

.i-title
{    
    font-size: 24px;
    /* line-height: 33px; */
    text-align: center; 
    color: #000000;
    /* display: flex; */
    /* font-family: 'Manrope';
    font-weight: 300; */
    font-family: 'JetBrains Mono';
    font-weight: 100;
    color: #000000;
    z-index: 1;
    /* margin-bottom: 200px; */
    /* padding-bottom: 600px; */

}
.title
{
    z-index: 1;
    margin-bottom: -300px;

    /* margin-top: -300px; */
}
.i-circle
{
    background-color: #ACACAC;
    border-radius: 50%;
    display: inline-block;
    height: 500px;
    width: 500px;
}
.circle
{
    /* background-color: purple;
    border-radius: 50%;
    display: inline-block;
    height: 500px;
    width: 500px; */
    position: absolute;
    z-index: 0;
}
.i-name-and-title
{
    margin-top: 200px;

}

.blobs-container 
{
  display: flex;
  /* position: absolute; */
  /* background-color: gray; */
  justify-content: center;
  align-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 20px;
  margin-top: 40px;
  height: 100px;
  width: 100px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
  
}

.blob.blue {
  background: rgba(199, 216, 233, 1);
  box-shadow: 0 0 0 0 rgba(199, 216, 233, 1);
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(199, 216, 233, 0.8);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(52, 172, 224, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

.arrow-box
{
    position: absolute;
    height: 225px;
    width: 250px;
}

.i-arrow
{
    width:30%;
    height:30%;
    padding-top: 50px;
}

.portfolio
{
    /* position: absolute;
    top: 300px;
    right: -210px; */
    font-weight: 800;
    font-size: 128px;
    /* position: static; */
    /* top: 300px; */
    /* offset: 20px; */
    /* line-height: 175px; */
    /* color: #FAFAFA; */
    color: rgba(217, 224, 231, 0.267);
    /* color: white; */
    transform: rotate(-90deg);
    /* text-shadow: rgba(0, 0, 0, 0.2) 1px 2px; */
    /* padding-top: 6ex; */
    position: absolute;
    /* padding-top: 475px; */
    padding-top: 15%;
    /* z-index: 1; */

}

@media screen and (min-width: 300px)
{
    .i-name
    {
        font-size: 50px;
    }

    .i-title
    {
        font-size: 18px;
    }

    /* .portfolio
    {
        font-weight: 800;
        font-size: 128px;
        padding-top: 15%;
        padding-bottom: 40%;
        padding-left: 80%;
    } */

}

@media screen and (min-width: 768px)
{
    .i-name
    {
        font-size: 64px;
    }

    .i-title
    {
        font-size: 24px;
    }

/* .portfolio
{
    font-weight: 800;
    font-size: 128px;
    padding-top: 15%;
} */

    
}

@media screen and (min-width: 1200px)
{
    .i-name
    {
        font-size: 64px;
    }

    .i-title
    {
        font-size: 24px;
    }
    
/* .portfolio
{
    font-weight: 800;
    font-size: 128px;
    padding-top: 30%;
} */


}