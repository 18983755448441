/* .pp2
{
    background-color: #F9F9F9;
} */
.pp2-grid-container 
{
    display: grid;
    grid-template-rows: auto;
    padding: 20px;
    /* border: solid red; */
    /* width: 85vw; */
    /* width: 100%; */
    /* padding-right: 100%; */
    /* margin-right: 12vw; */

}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
.pp2-grid-item-row 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: left;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    padding: 20px;
    font-size: 30px;
    /* border: solid green; */
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in rows */
.pp2-grid-item-col 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 30px;
    /* border: solid purple; */
}

.pp2-title
{
  font-weight: 800;
  font-size: 96px;
  padding-top: 200px;
}

.pp2-description
{
    /* width: 90%; */
    /* width: 20px; */
    /* padding: 100px; */
}

.pp2-title-line
{
  border: 1.5px solid #000000;
  display: flex;
  margin-bottom: 20px;
}

.pp2-summary
{
    text-align: center;
}

.pp2-role
{
    text-align: left;
    font-style: italic;
}

.summary-align
{
    align-items: center;
}

.pp2-subtitle
{
  font-weight: 600;
  font-size: 32px;
  z-index: 2;
  /* align-items: left; */
  /* text-align: left; */
}

.pp2-role-list
{
  font-weight: 200;
  font-size: 16px;
  line-height: 20px;
  color: #5C5C5C;
  text-align: left;
  list-style: none;
}

.tag
{
    margin: 10px;
    width: 100px;
}

.links-align
{
    align-items: center;
}

.links-row
{
    /* align-items: left; */
    align-items: flex-start;
    /* margin-left: -30px; */
    /* padding-left: 50%; */
    margin-left: 45%;
}

button:hover
{
  width: 100%;
  /* background-color: rgba(199, 216, 233, 1); */
  background-color: #0077b6;
}

button
{
  transition: .8s all ease;

  width: 120px;
}

.button-flex
{
  width: 200px;
  align-self: center;
}

.pp-flexbox-item
{
  /* Fits and resizes everything into its respective box */
  min-width: min-content;

  /* Styling */
  /* padding: 0px; */
  margin: 20px; 
  /* border: 3px solid #333;
  background-color: #dfdfdf; */
}



/* 
.multiple-buttons
{
  margin-left: -20px;
  margin-right: 30px;
} */

p
{
    font-weight: 200;
    font-size: 16px;
    color: #5C5C5C;
    text-align: left;
}

.gray-section
{
    background-color: #F9F9F9;

}
.white-section
{
    background-color:white;

}

.section-separator
{
    padding: 50px;
}

.pp2-back
{
  text-decoration: none;
  color: black;
  /* margin-top: 300px; */
  font-weight: 800;
  font-size: 32px;
  z-index: 2;
}
.pp2-back:hover
{
  color: gray;
}

.pp2-back-line
{
  width: 40px;
  border: 1.5px solid #000000;
  display: flex;
  margin-left: 12px;
  /* margin-bottom: 200px; */
}

/* .back
{
    align-items: center;
} */

.back
{
    align-items: center;
}

.pp2-grid-container-five-col 
{
    display: grid;
    /* Auto spacing changes column width based on size of the content */
    /* Shorthand method for evenly-spacing the 5 columns */
    grid-template-columns: repeat(5, minmax(0, 1fr));
    /* Align each a-flexbox-item by the top */
    align-items:flex-start;
    /* width: 100%; */
}

/* Media Queries */
@media screen and (min-width: 300px)
{
    .pp2-grid-container-five-col
    {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    /* .pp2-description
    {
        font-size: 10px;
    } */

    .pp2-title
    {
    font-weight: 800;
    font-size: 86px;
    padding-top: 200px;
    }

    .long-title
    {
        font-size: 47px;
    }

    .links-row
    {
        align-items: flex-start;
        margin-left: 17%;
    } 

}

@media screen and (min-width: 768px)
{
    .pp2-grid-container-five-col
    {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
/* 
    .pp2-description
    {
        font-size: 2px;
    } */

    .pp2-title
    {
    font-weight: 800;
    font-size: 96px;
    padding-top: 200px;
    }

    .long-title
    {
        font-size: 86px;
    }

    /* .links-row
    {

        margin-left: 44%;
    } */
    .links-row
    {
        align-items: flex-start;
        margin-left: 45%;
    } 
}

@media screen and (min-width: 1200px)
{
    .pp2-grid-container-five-col
    {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    /* .pp2-description
    {
        font-size: 2px;
    } */

    .pp2-title
    {
    font-weight: 800;
    font-size: 96px;
    padding-top: 200px;
    }

    .long-title
    {
        font-size: 86px;
    }

    .links-row
    {
        align-items: flex-start;
        margin-left: 45%;
    }   
}