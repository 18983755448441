/* Holds the entire grid */
.a-grid-container 
{
    display: grid;
    /* grid-template-columns: auto auto; */
    /* Shorthand method for evenly-spacing the 2 columns */
    /* grid-template-columns: repeat(4, minmax(0, 1fr));
    background-color: #2196F3; */
    padding: 10px;
    /* height: 20px; */
    
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
.a-grid-item-col 
{   
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
}

.a-grid-item-col-space 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;

    height: 100px;
}

  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
.a-grid-item-row 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    /* ALIGN TO TOP */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
    /* height: 20px; */

}

.a-flexbox-item
{
     /* Fits and resizes everything into its respective box */
    /* min-width: min-content; */
    /* Styling */
    margin: 10px;
    /* border: 3px solid #333;
    background-color: #dfdfdf; */
    /* height: 30px; */
    
}

.a-flexbox-item-rounded
{
     /* Fits and resizes everything into its respective box */
    /* min-width: min-content; */

    /* Styling */
    margin: 10px;
    /* border: 3px solid #333; */
    /* background-color: white; */
    border-radius: 5px;
    box-shadow: 0px 0px 1.2px rgba(0, 0, 0, 0.8);

    /* min-width: 300px; */
}

.a-grid-item-col-l-align
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-start;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
    
}

.a-subject-and-details-col
{
    padding-left: 10px;
    height: 60px;
    

}

.a-grid-item-col-r-align
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: flex-end;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
}

.a-grid-container-single-row
{
    display: grid;
    grid-template-rows: auto;
    /* background-color: #2196F3; */
    padding: 10px;
}

.a-grid-container-two-cols
{
    display: grid;
    grid-template-columns: auto auto;
    /* background-color: #2196F3; */
    padding: 10px;
    /* height:80px; */
}

.a-grid-container-two-rows
{
    display: grid;
    grid-template-rows: auto auto;
    /* background-color: #2196F3; */
    padding: 10px;
}
/* Specific styling */
button
{
    text-align: center;
    font-size: 12px;
    display: flex;
    font-weight: 800px;
    color: #FFFFFF;
    /* background-color: #EDF3F9; */
    background-color: #0077b6;
    background: #000000;
    border-radius: 25px;
    /* Removes bevel */
    border: 0;
    width: 87px;
    height: 25.69px;
    align-items: center;
    justify-content: center;
}
.a-title
{
    font-weight: 800;
    font-size: 30px;
    color: black
}
.a-line
{
    border: 1.5px solid #000000;
    display: flex;
}
/* May need to change the size later */
.a-myavatar
{
    width: 300px;
    height: 300px;
}

.avatar-flexbox
{
    margin-left: 80px;
}

.a-my-description
{
    /* width: 950px; */
    /* height: 95px; */
    
    font-weight: 200;
    font-size: 16px;
    text-align: left;
    line-height: 20px;
    /* padding: 10px; */
}

.description-flexbox
{
    margin-right: 80px;
}

.a-resume-button
{
    /* width: 87px;
    height: 25.69px;
    border-radius: 200px; */
    /* align-items: left;
    justify-content: left; */
    /* flex-wrap: wrap; */
    width: 87px;
    /* background-color: rgba(199, 216, 233, 1); */
    /* background: #EDF3F9; */
    
    
}



.a-subjects
{
    
    /* ISSUE: NEED TO MOVE THIS DOWN TO MIDDLE OF BOX */
    /* Text */

    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    /* min-width: 120px; */
    /* height: 50px; */
    /* height: 20px; */
    width: fit-content;
    /* height:fit-content; */
    /* Keep all text on one line */
    white-space: nowrap;
}


.a-subjects-descr
{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    /* width: 10px; */
    /* height: 100px; */
}
.a-icon
{
    /* position: absolute; */
    width: 29px;
    height: 30.71px;
    /* left: 152px;
    top: 2854px; */
}

.a-subject-container
{
    /* border-left: 3px solid rgba(0, 0, 0, 0.25); */
    /* padding-left:20px; */
    /* width: 10px;
    height: 5px; */
    /* height: 72px; */
    /* width: 70px; */
    margin-bottom: 0px;
    margin-top: 0px;
}

.a-subject-and-details-container
{
    border-left: 3px solid rgba(0, 0, 0, 0.25);
    margin: 0px;
    width: 200px;
}

.a-descr-container
{
    /* padding-left:20px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.a-skills-software
{
    max-width: 374px;
    min-width: 180px;
    height: 257px;
    border-left: 3px solid rgba(0, 0, 0, 0.25);
    padding-top: 30px;
    
}

.a-skills-software-title
{
    /* width: 150px; */
    /* min-width: 180px; */
    height: 26px;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
}
.a-skills-software-list
{
    /* position: absolute; */
    /* min-width: 296px; */
    height: 188px;
    font-weight: 200;
    font-size: 15px;
    line-height: 19px;
    color: #000000;
    text-align: left;
    list-style-type: none;
    
}

.a-list-div
{
    word-wrap: break-word;
}
.a-grid-container-subjects
{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    /* background-color: #2196F3; */
    padding: 10px;
}

/* .subject-boxes
{
    height: 30px;
} */

.subjects
{
    padding: 0px;
    /* height: 20px; */
}

.subject-and-details
{
    margin: 0px;
}
.button-with-fx:hover
{
  width: 100%;
  /* background-color: rgba(199, 216, 233, 1); */
    /* background-color: rgba(199, 216, 233, 1); */
  /* text-decoration-color: rgba(199, 216, 233, 1); */
}

.button-with-fx
{
    transition: .8s all ease;
    text-decoration: none;
    color: white;
    /* background-color: black; */

    
}

.a-resume-button-flex
{
    width: 200px;
}




@media screen and (min-width: 300px)
{
    .a-grid-container-two-cols
    {
        grid-template-columns: auto;
        padding: 10px;
    }

    .avatar-flexbox
    {
        /* margin-left: -30px; */
        /* margin-left: -20%; */
        margin-left: 0;
    }

    .avatar-column
    {
        align-items: center;
    }

    .a-grid-item-col-r-align
    {
        align-items: center;
    }

    .a-grid-container 
    {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .a-skills-software-title
    {
        /* max-width: 374px; */
        width: 225px;
    }

    .a-skills-software
    {
        /* max-width: 374px; */
        width: 225px;

    }

    .a-skills-software-list
    {
        /* max-width: 374px; */
        width: 225px;

    }

    .a-my-description
    {
        text-align: center;
    }

    .description-flexbox
    {
        margin-right: 0px;
    }

    /* .a-resume-button-flex
    { */
        /* margin-left: 40%; */
        /* align-items: center; */
        /* margin-left: 0; */
    /* } */

    .a-resume-button-flex
    {
        margin-left: 40%;
        /* margin-left: 10px; */
        align-items: center;
    }
    
}

@media screen and (min-width: 768px)
{

    .a-grid-container-two-cols
    {
        grid-template-columns: auto auto;
        padding: 10px;
    }

    .a-grid-item-col-r-align
    {
        align-items: flex-end;
    }

    .avatar-flexbox
    {
        margin-left: 0px;
    }
    
    .description-flexbox
    {
        margin-right: 80px;
    }

    .a-grid-container 
    {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .a-skills-software-title
    {
        width: 310px;
    }

    .a-skills-software
    {
        width: 310px;
    }

    .a-skills-software-list
    {
        width: 310px;
    }

        .a-my-description
    {
        text-align: left;
    }

    .description-flexbox
    {
        margin-right: 0px;
    }

    .a-resume-button-flex
    {
        margin-left: 10px;
    }

}

@media screen and (min-width: 1200px)
{

    .a-grid-container-two-rows
    {
        grid-template-columns: auto auto;
        padding: 10px;
    }

    .a-grid-container 
    {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .a-skills-software-title
    {
        width: 374px;
    }

    .a-skills-software
    {
        width: 374px;
    }

    .a-skills-software-list
    {
        width: 374px;
    }

    .a-my-description
    {
        text-align: left;
    }

    .description-flexbox
    {
        margin-right: 80px;
    }

    .a-resume-button-flex
    {
        margin-left: 10px;
    }
    
    .avatar-flexbox
    {
        margin-left: 80px;
    }
}
