/* Holds the entire grid */
.c-grid-container {
    display: grid;
    /* grid-template-columns: auto auto auto; */
    /* grid-template-columns: repeat(3, minmax(0, 1fr)); 
    background-color: #2196F3; */
    padding: 10px;
  }
.c-grid-container-two-cols 
{
  display: grid;
  grid-template-columns: auto;
  /* background-color: #2196F3; */
  padding: 10px;
}

  .c-grid-container-single-row
{
    display: grid;
    grid-template-rows: auto;
    /* background-color: #2196F3; */
    padding: 10px;
}

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
  .c-grid-item-col {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    padding: 20px;
    font-size: 30px;
    text-align: center;
    /* width: 300px; */
    /* background-color: #F8F8F8 */
  }

/* A cell in the grid that is also a flex-container, which holds flex items */
/* This one specifically is meant to arrange items in columns */
  .c-grid-item-col-left-align {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: column;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    /* padding: 20px; */
    font-size: 30px;
    text-align: left;
    /* padding: 100px; */
    /* width: 400px; */
    
    
  }

  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
  .c-grid-item-row {
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    align-items: center;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    font-size: 30px;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;

  }
  .c-flexbox-item
{
     /* Fits and resizes everything into its respective box */
    min-width: min-content;

    /* Styling */
    margin: 10px;
    /* border: 3px solid #333;
    background-color: #dfdfdf; */
    /* width: 20vh; */
}

 /* Specific sytling */

 .c-title
 {
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  /* color: #FAFAFA; */
  color: #000000;
  /* align-items: center; */
    /* align-self: center; */
  /* align-content: center; */
  /* justify-content: center; */
  /* justify-self: center; */
  /* justify-items: center; */
 }

 .c-title-line
{
    /* border: 1.5px solid #FAFAFA; */
    border: 1.5px solid #000000;
    /* fill: white; */
    /* border: 1.5px solid #000000; */
    display: flex;
    /* align-items: center; */
    /* align-self: center; */
    /* align-content: center; */
    /* justify-content: center; */
    /* justify-self: center; */
    /* justify-items: center; */
}



/* ISSUE: NEED TO BE MOVE UP TO TOP OF THIS GRID ITEM */
.c-social-icons
{
  width: 35.57px;
  height: 35.57px;
}

.socials
{
  padding-bottom: 250px;
  padding-top:0px;
}

.c-line-container
{
  position: relative;
  bottom: 15px;
  align-items: center;
  background-color:rgba(0, 0, 0, 0);
  border: none;
}

/* Popup Specific Styling */
.popup
{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    /* width: fit-content; */

}

.popup-inner
{
    position: relative;
    padding: 32px;
    /* Change width of entire email popup section */
    /* width: 800px; */
    /* height: 50%; */
    /* max-width: 100%; */
    max-width: 800pxs;
    /* min-width: 200px ; */
    max-height: 800px;
    /* height: 100vh; */
    background-color: #FFF;
      /* background-color: blue; */
    /* width: 800px; */

}

.popup-inner .close-btn
{
    position: absolute;
    top: 16px;
    right: 16px;
    /* width: fit-content; */
    width: 25px;
    height: 25px;
    padding: 10px;
    z-index: 1;
    border-radius: 5px;
}


/* Specific Styling */

button
{
    text-align: center;
    font-size: 12px;
    display: flex;
    font-weight: 800px;
    color: #FFFFFF;
    background-color: rgba(199, 216, 233, 1);
    background: #000000;
    border-radius: 5px;
    /* Removes bevel */
    border: 0;
    width: 87px;
    height: 25.69px;
    align-items: center;
    justify-content: center;
    cursor:pointer; 
}
/* form */
/* { */
    /* margin-top: 20px; */
/* } */

input
{
  /* width: 100%; */
  height: 50px;
  border:none;
  border-bottom: 1px solid black;
  margin:10px 0px;
  font-size: 14px;
  padding-left: 10px;
  width:90%;
  min-width: 5spx;
}

input:hover
{
 cursor:pointer;
}

textarea
{
  margin:10px 0px;
  font-size: 14px;
  padding-left: 10px;
  width: 350px;
  height: 100px;
  /* Prevent user from resizing message textarea too much  */
  /* resize: vertical;  */
  resize:both; 
  max-height: 300px; 
  min-height: 100px;
  max-width: 350px;
  /* min-width: 5px; */
  /* resize: horizontal; max-width: 350px; min-width: 200px; */
}

.c-grid-container-email
{
  display: grid;
  grid-template-columns: auto auto;
  /* background-color: #2196F3; */
  padding: 10px;
  /* Is this needed? */
  height: 100%;

}

.email:hover
{
  cursor:pointer;
}

.email
{
  margin-left: 30px;
  margin-right: 30px;
}

.email-icon
{
  width: 40px;
  height: 40px;
  padding-top: 5px; 
}

.title
{
  /* margin-bottom: 30%; */
   margin-top: 300px;
   z-index: 0;
}

.c-grid-item-col-rightside 
{
  /* Makes this grid-item into a flex container */
  display: flex;
  /* Center items horizontally */
  justify-content: left;
  /* Center items vertically */
  align-items: left;
  /* You can set direction as column or row depending on what you're putting into the flex container */
  flex-direction: column;

  /* Styling */
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 20px;
  font-size: 30px;
  text-align: left;
  margin: 20px;
}
.c-email-popup-subtitle
{
  font-size: 40px;
  font-weight: 200;
}

.c-email-popup-subtitle2
{
  font-size: 35px;
  font-weight: 200;
}

.button-with-fx:hover
{
  width: 100%;
  background-color: rgba(199, 216, 233, 1);
}

.button-with-fx
{
  transition: .8s all ease;

}
.button-with-fx::after
{
  transition: .8s all ease;
}

.thx-mssg
{
  font-size: 18px;
  font-weight: 200;
}

@media screen and (min-width: 300px)
{
  .c-email-popup-subtitle
  {
    font-size: 20px;
    /* max-width: 15em; */
  }

  .c-email-popup-subtitle2
  {
    font-size: 15px;
    /* max-width: 15em; */

  }

  .c-grid-container-two-cols
  {
    display: grid;
    grid-template-rows: auto auto;
    padding: 0px;
    margin: 0px;
  }

  .modal-color-half
  {
    padding: 20px;
    margin: 0px;
    margin: -30px;
    background-color: #F8F8F8;
  }

    .c-grid-item-col
  {
    /* padding: 20px; */
    margin: 0px;
    /* margin: -30px;   */
  }
  textarea
  {
    /* Prevent user from resizing message textarea too much  */
    /* resize: vertical;  */
    resize:both; 
    max-height: 100px; 
    min-height: 50px;
    max-width: 23em;
    /* min-width: 5px; */
    /* resize: horizontal; max-width: 350px; min-width: 200px; */
  }
  input
  {
    height: 30px;
  }

  .c-grid-item-col-rightside
  {
    margin: 20px;
  }

  .popup-inner
  {
    padding: 0px;
  }
}

@media screen and (min-width: 768px)
{
    .c-email-popup-subtitle
  {
    font-size: 40px;
    max-width: 400px;

  }

 .c-email-popup-subtitle2
  {
    font-size: 35px;
    max-width: 400px;

  }

  .c-grid-container-two-cols
  {
    display: grid;
    grid-template-columns: auto auto;
  }

  /* input
  {
    width: 350px;
  }

  textarea
  {
    width: 350px;
    height: 100px;
  } */

  .c-grid-container-two-cols
  {
    padding: 0px;
    margin: 0px;
  }

  .modal-color-half
  {
    padding: 0px;
    margin: 0px;
    /* Added  */
    margin: -30px; 
    /* padding: -20px; */
  }

  textarea
  {
    /* Prevent user from resizing message textarea too much  */
    /* resize: vertical;  */
    resize:both; 
    max-height: 300px; 
    min-height: 50px;
    max-width: 23em;
    /* min-width: 20em; */
    /* min-width: 5px; */
    /* resize: horizontal; max-width: 350px; min-width: 200px; */
  }
  input
  {
    height: 30px;
  }

  .popup-inner
  {
    padding: 32px;
  }
}

.c
{
  background-color: #ffffff;
  /* background-color: #FAFAFA; */
  /* background-color: #111111; */

}

@media screen and (min-width: 1200px)
{
  .c-email-popup-subtitle
  {
    font-size: 40px;
    max-width: 400px;

  }

 .c-email-popup-subtitle2
  {
    font-size: 35px;
    max-width: 400px;
  }

  .c-grid-container-two-cols
  {
    display: grid;
    grid-template-columns: auto auto;
  }

  /* input
  {
    width: 350px;
  }

  textarea
  {
    width: 350px;
    height: 100px;
  } */

  textarea
  {
    /* Prevent user from resizing message textarea too much  */
    /* resize: vertical;  */
    resize:both; 
    max-height: 300px; 
    min-height: 100px;
    max-width: 350px;
    /* min-width: 5px; */
    /* resize: horizontal; max-width: 350px; min-width: 200px; */
  }

  .popup-inner
  {
    padding: 32px;
  }
}
