.Loader
{
    text-align: center;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.welcome-text
{
    font-weight: 700;
    font-size: 30px;
    position: absolute;
    text-align: center;
}