/* Holds the entire grid */
.f-grid-container 
{
    display: grid;
    /* grid-template-columns: auto auto; */
    /* Shorthand method for evenly-spacing the 2 columns */
    grid-template-columns: auto;
    /* background-color: #2196F3; */
    /* padding: 10px; */
    /* height: 20px; */
    /* background-color: rgba(27,25,26,255); */
    /* background-color: #F9F9F9; */
    /* background-color: #000000; */
  /* background-color: #111111; */
  background-color: #ffffff;
    /* background-color: ; */

    /* padding-right: 12vw; */

}

  /* A cell in the grid that is also a flex-container, which holds flex items */
    /* This one specifically is meant to arrange items in rows */
.f-grid-item-row 
{
    /* Makes this grid-item into a flex container */
    display: flex;
    /* Center items horizontally */
    justify-content: flex-end;
    /* Center items vertically */
    /* ALIGN TO TOP */
    align-items: flex-end;
    /* You can set direction as column or row depending on what you're putting into the flex container */
    flex-direction: row;

    /* Styling */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    /* padding: 20px; */
    font-size: 30px;
    text-align: center;
    /* height: 20px; */

}

.f-flexbox-item
{
     /* Fits and resizes everything into its respective box */
    /* min-width: min-content; */
    /* Styling */
    margin: 10px;
    /* border: 3px solid #333;
    background-color: #dfdfdf; */
    /* height: 30px; */
    align-self: flex-end;
    position: relative;
    
}

.f-footer-text
{
  /* position: absolute; */
  /* width: 123px;
  height: 14px;
  left: 1231px;
  top: 4276px; */

  /* font-family: 'Manrope';
  font-style: normal; */
  font-weight: 300;
  font-size: 12px;
  /* line-height: 16px; */
  text-align: left;
  color: #808080;
  /* color: #ffffff; */
  /* white-space: nowrap; */
}

.copyright-symbol
{
  display: inline;
}

.f-copyright-note
{
  display: inline;
}

@media screen and (min-width: 300px)
{
    .f-copyright-note
    {
        font-size: 10px;
    }

    .copyright-symbol
    {
        font-size: 10px;
    }

    .f-grid-container-single-row 
    {
        padding: 0px;
    }
}

@media screen and (min-width: 768px)
{
    .f-copyright-note
    {
        font-size: 14px;
    }

    .copyright-symbol
    {
        font-size: 14px;
    }

    .f-grid-container-single-row 
    {
        padding: 0px;
    }
}

@media screen and (min-width: 1200px)
{
    .f-copyright-note
    {
        font-size: 16px;
    }

    .copyright-symbol
    {
        font-size: 16px;
    }

    .f-grid-container-single-row 
    {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 72px;
        padding-right: 72px;
    }
}